import * as Sentry from '@sentry/sveltekit';
import {building} from "$app/environment";
import {env as publicEnv} from "$env/dynamic/public";
import {get} from "svelte/store";
import {replay} from "$lib/stores/client-only.js";


if (!building && publicEnv.PUBLIC_LOCAL_DEVELOPMENT !== "true") {
    replay.set(Sentry.replayIntegration({
                // Additional SDK configuration goes in here, for example:
                maskAllText: true,
                blockAllMedia: true,
                mask: [".sentry-mask"],  // Cascades to children
                unmask: [".sentry-unmask"], // Cascades to children
                unblock: [".sentry-unblock"],  // DOES NOT cascade to children (from what I can see. Use setContext("sentry-unblock", "sentry-unblock") to cascade (with picture component))

                // loading worker script from locally hosted version for CSP (https://docs.sentry.io/platforms/javascript/guides/astro/session-replay/configuration/#using-a-custom-compression-worker)
                workerUrl: "https://healper-static.s3.eu-central-1.amazonaws.com/s/client/sentry/worker.min.js"
            }));




    Sentry.init({
        dsn: 'https://ebbd7a93350749ea9b6aae7350e4b648@o574649.ingest.sentry.io/5725912',
        environment: publicEnv.PUBLIC_SENTRY_ENVIRONMENT,

        //-----Performance Monitoring------
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.01,

        //-----SESSION REPLAY------
        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.0,

        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,

        // If you don't want to use Session Replay, just remove the line below:
        integrations: [
            get(replay)
        ],
    });
    Sentry.setTag("front-end-engine", "sveltekit");
    Sentry.setUser(null);
}

// function informClientOfError(error) {
//     console.log("An error occurred. Please try again later. " + error)
//     alert("An error occurred. Please try again later. " + error);
//     // console.error(error);
// }
//
// // If you have a custom error handler, pass it to `handleErrorWithSentry`
// export const handleError = informClientOfError;
