import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50')
];

export const server_loads = [2,4,5,8,10,12,15];

export const dictionary = {
		"/[[lang=locales]]/admin/payments/emailtemplate/[[pk=id]]/editor": [~18,[2,4],[3]],
		"/[[lang=locales]]/admin/payments/smstemplate/[[pk=id]]/editor": [~19,[2,4],[3]],
		"/[[lang=locales]]/admin/svelte/clear-cache": [~20,[2,4],[3]],
		"/[[lang=locales]]/admin/svelte/image-cropper": [21,[2,4],[3]],
		"/[[lang=locales]]/admin/svelte/merge-users/[sourceId=id]/[targetId=id]": [~22,[2,4],[3]],
		"/[[lang=locales]]/admin/svelte/scripts": [~23,[2,4],[3]],
		"/[[lang=locales]]/admin/svelte/scripts/[scriptName]": [~24,[2,4],[3]],
		"/[[lang=locales]]/d/data-processing-agreement": [25,[2],[3]],
		"/[[lang=locales]]/d/for-therapists": [~26,[2],[3]],
		"/[[lang=locales]]/d/gdpr": [27,[2],[3]],
		"/[[lang=locales]]/d/help-center": [~28,[2],[3]],
		"/[[lang=locales]]/d/help-center/[typeSlug=helpCenterTypeSlug]": [29,[2,5],[3,6]],
		"/[[lang=locales]]/d/help-center/[typeSlug=helpCenterTypeSlug]/[titleSlug]/[slug]": [~30,[2,5],[3,6]],
		"/[[lang=locales]]/d/iframes/libsodium-sandbox": [31],
		"/[[lang=locales]]/d/iframes/quill-to-pdf": [32],
		"/[[lang=locales]]/d/sitemaps": [~33,[2,7],[3]],
		"/[[lang=locales]]/d/sitemaps/[slug]/[[subSlug]]": [~34,[2,7],[3]],
		"/[[lang=locales]]/d/terms-of-service": [35,[2],[3]],
		"/[[lang=locales]]/d/therapist-terms-of-service": [36,[2],[3]],
		"/[[lang=locales]]/d/tmp/sentry-modal-issue": [42,[2],[3]],
		"/[[lang=locales]]/d/tmp/(staff-authed)/styleguide": [37,[2,8],[3]],
		"/[[lang=locales]]/d/tmp/(staff-authed)/svelte-parsing": [~38,[2,8],[3]],
		"/[[lang=locales]]/d/tmp/(staff-authed)/test-error": [40,[2,8],[3]],
		"/[[lang=locales]]/d/tmp/(staff-authed)/test2": [41,[2,8],[3]],
		"/[[lang=locales]]/d/tmp/(staff-authed)/test": [~39,[2,8],[3]],
		"/[[lang=locales]]/information-support": [~43,[2],[3,9]],
		"/[[lang=locales]]/information-support/[categorySlug]": [~44,[2,,10],[3,9,11]],
		"/[[lang=locales]]/information-support/[categorySlug]/[articleSlug]": [~45,[2,,10,12],[3,9,11,13]],
		"/[[lang=locales]]/information-support/[categorySlug]/[articleSlug]/[slug]": [~46,[2,,10,12],[3,9,11,13,14]],
		"/[[lang=locales]]/psychologists/(therapist-authed)/calendar-s": [~47,[2,15],[3]],
		"/[[lang=locales]]/psychologists/(therapist-authed)/dashboard": [~48,[2,15],[3]],
		"/[[lang=locales]]/psychologists/(therapist-authed)/dashboard/export-all-journals": [~49,[2,15],[3]],
		"/[[lang=locales]]/psychologists/(therapist-authed)/therapy": [~50,[2,15],[3]],
		"/[[lang=locales]]/[landingPage=landingExceptions]": [~17,[2],[3]],
		"/[[lang=locales]]": [~16,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';